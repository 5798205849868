import React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { ArrowButton, Footer, LocalizedMessage, NavMenu, ScrollTopButton } from 'components';

import classes from './PricingPage.module.scss';

const rates = [
  {
    title: 'basic',
    price: 11400,
  },
  {
    title: 'standard',
    price: 21500,
  },
  {
    title: 'pro',
    price: 39000,
  },
  {
    title: 'flexible',
  },
];

const PricingPage = () => (
  <div className={classes.Layout}>
    <LocalizedMessage id="site.title.pricing">
      {(localizedTitle) => (
        <Helmet title={localizedTitle} />
      )}
    </LocalizedMessage>

    <header id="top">
      <NavMenu />
      <section className={classes.Promo}>
        <LocalizedMessage id={[
          'pricing-page.promo.title.rates',
          'pricing-page.promo.title.and',
          'pricing-page.promo.title.prices',
        ]}
        >
          {
            (localizedRates, localizedAnd, localizedPrices) => (
              <h1 className={classes.Title}>
                <span>{localizedRates}</span>
                &nbsp;
                {localizedAnd}
                &nbsp;
                <span>{localizedPrices}</span>
              </h1>
            )
          }
        </LocalizedMessage>
        <ArrowButton destinationId="#prices" />
      </section>
    </header>

    <main className={classes.Main} id="prices">
      <h2 className={classes.MainTitle}>
        <LocalizedMessage id="pricing-page.table.caption" />
        :
      </h2>
      <ul className={classes.Rates}>
        {rates.map(({ title, price }, index) => (
          <li className={classes.RatesItem} key={index}>
            <div className={cx(classes.RatesText, classes.RatesTextHighlighted, classes.RatesTextForName)}>
              <LocalizedMessage id={`pricing-page.table.${title}-rate.title`} />
            </div>
            <div className={cx(classes.RatesText, classes.RatesTextForInfo)}>
              <LocalizedMessage id={`pricing-page.table.${title}-rate.info`} />
            </div>
            <div className={cx(classes.RatesText, classes.RatesTextHighlighted, classes.RatesTextForPrice)}>
              {price === undefined
                ? <LocalizedMessage id="pricing-page.flexible-price" />
                : price.toLocaleString('ru', {
                  style: 'currency',
                  currency: 'rub',
                  currencyDisplay: 'symbol',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </div>
          </li>
        ))}
      </ul>
    </main>

    <ScrollTopButton destinationId="#top" startSectionId="#prices" />
    <Footer />
  </div>
);

export default PricingPage;
