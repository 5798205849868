import React, { Component } from 'react';

class Page extends Component {
  canvas = null;

  state = {
    status: 'N/A',
    page: null,
    width: 0,
    height: 0,
  };

  componentDidMount () {
    const { pdf } = this.props;
    this.update(pdf);
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { pdf } = this.props;
    const { status } = this.state;

    return pdf !== nextProps.pdf || status !== nextState.status;
  }

  componentDidUpdate (nextProps) {
    this.update(nextProps.pdf);
  }

  setCanvasRef = (canvas) => {
    this.canvas = canvas;
  };

  update = (pdf) => {
    if (pdf) {
      this.loadPage(pdf);
    } else {
      this.setState({ status: 'loading' });
    }
  };

  loadPage (pdf) {
    const { index } = this.props;
    const { status, page } = this.state;

    if (status === 'rendering' || page !== null) return;

    pdf.getPage(index).then(
      (pageItem) => {
        this.setState({ status: 'rendering' });
        this.renderPage(pageItem);
      },
    );
  }

  renderPage (page) {
    const { scale } = this.props;
    const viewport = page.getViewport({ scale });
    const { width, height } = viewport;

    if (this.canvas) {
      const context = this.canvas.getContext('2d');
      this.canvas.width = width;
      this.canvas.height = height;

      page.render({
        canvasContext: context,
        viewport,
      });

      this.setState({ status: 'rendered', page, width, height });
    }
  }

  render () {
    const { width, height, status } = this.state;

    return (
      <div className={`pdf-page ${status}`} style={{ width, height }}>
        <canvas ref={this.setCanvasRef} />
      </div>
    );
  }
}

export default Page;
