import React from 'react';
import cx from 'classnames';
import classes from './Loader.module.scss';

const Loader = (props) => {
  const {
    position = 'absolute', type = 'ring', active = true, withText = false,
    withBackground = true, isSmall = false, className = null,
  } = props;
  const positionClass = position === 'inline' ? '' : classes.LoaderAbsolute;

  return (
    <div
      className={cx(
        classes.Loader,
        positionClass,
        {
          [classes.LoaderActive]: active,
          [classes.LoaderSmall]: isSmall,
          [classes.LoaderWithBackground]: position !== 'inline' && withBackground && !isSmall,
          [classes.LoaderRing]: type === 'ring',
          [classes.LoaderBubbles]: type === 'bubbles',
        },
        className,
      )}
    >
      <div className={classes.LoaderContent}>
        {
          type === 'bubbles'
            ? (
              <ul className={classes.LoaderAnimation}>
                {
                new Array(12).fill(null).map((value, index) => (
                  <li
                    key={index}
                    className={classes.LoaderAnimationItem}
                  />
                ))
              }
              </ul>
            )
            : (
              <svg className={classes.LoaderAnimation}>
                <circle
                  className={classes.LoaderAnimationItem}
                  cx="50%"
                  cy="50%"
                  r="50%"
                  fill="none"
                />
              </svg>
            )
        }
        {withText && !isSmall && (
          <p className={classes.LoaderText}>
            Loading
          </p>
        )}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  position: 'absolute',
  type: 'ring',
  active: true,
  withText: false,
  withDescription: false,
  withBackground: true,
  isSmall: false,
  className: null,
};

export default Loader;
