import React, { PureComponent } from 'react';
import cx from 'classnames';

import { Button, Input, Loader, LocalizedHtml, LocalizedMessage, localizeMessage, showNotification } from 'components';
import { sendMail, validateEmail } from 'helpers';

import classes from './AccessForm.module.scss';

const Errors = {
  REQUIRED_FIELDS: 'accessForm.error.requiredFields',
  MIN_LENGTH: 'accessForm.error.minLength',
  INVALID_PHONE: 'accessForm.error.invalidPhone',
  INVALID_EMAIL: 'accessForm.error.invalidEmail',
};

class AccessForm extends PureComponent {
  form = null;

  state = {
    phone: '',
    phoneFormat: '',
    email: '',
    company: '',
    errors: {},
    isSubmitted: false,
    isLoading: false,
  };

  setFormRef = (ref) => {
    this.form = ref;
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
      this.setState(prevState => {
        const { email, ...errors } = prevState.errors;

        return {
          errors,
          isSubmitted: false,
          email: value.trim(),
        };
      });
    }

    if (name === 'company') {
      this.setState(prevState => {
        const { company, ...errors } = prevState.errors;

        return {
          errors,
          isSubmitted: false,
          company: value,
        };
      });
    }
  };

  handlePhoneChange = (inputNumber, selectedCountry) => {
    const newFormat = selectedCountry.format || '';

    this.setState(prevState => {
      const { phone, ...errors } = prevState.errors;

      return {
        errors,
        isSubmitted: false,
        phone: newFormat.length > 0 ? inputNumber.slice(0, newFormat.length) : inputNumber,
        phoneFormat: newFormat,
      };
    });
  };

  handleFormSubmit = async (e) => {
    const { phone, email, company } = this.state;

    e.preventDefault();

    const errors = this.validateFields();

    if (Object.keys(errors).length) {
      this.setState({
        errors,
        isSubmitted: false,
      });

      if (errors.phone && this.form?.phone) {
        this.form.phone.focus();
      } else if (errors.email && this.form?.email) {
        this.form.email.focus();
      } else if (errors.company && this.form?.company) {
        this.form.company.focus();
      }

      return;
    }
    this.setState({ isLoading: true });

    try {
      await sendMail({ phone, email, company });

      this.setState({
        errors: {},
        phone: '+7',
        email: '',
        company: '',
        isSubmitted: true,
        isLoading: false,
      });
    } catch (error) {
      showNotification(localizeMessage({ id: 'accessForm.submit.error' }), 'failure');
      console.warn(error);
      this.setState({
        errors: {},
        isSubmitted: false,
        isLoading: false,
      });
    }
  };

  validateFields = () => {
    const { phone, phoneFormat, email, company } = this.state;
    const errors = {};

    const phoneLength = phone.length;
    const emailLength = email.length;
    const formatLength = phoneFormat.length;

    if (phoneLength === 0) {
      errors.phone = Errors.REQUIRED_FIELDS;
    } else if (formatLength > 0 && phoneLength < formatLength) {
      errors.phone = Errors.MIN_LENGTH;
    } else if (formatLength > 0 && phoneLength > formatLength) {
      errors.phone = Errors.INVALID_PHONE;
    }

    if (emailLength === 0) {
      errors.email = Errors.REQUIRED_FIELDS;
    } else if (emailLength < 4) {
      errors.email = Errors.MIN_LENGTH;
    } else if (!validateEmail(email)) {
      errors.email = Errors.INVALID_EMAIL;
    }

    if (company.length === 0) {
      errors.company = Errors.REQUIRED_FIELDS;
    }

    return errors;
  };

  render () {
    const { phone, email, company, errors, isSubmitted, isLoading } = this.state;
    const errorValues = Object.values(errors);

    return (
      <div className={classes.AccessForm}>
        <header className={classes.Header}>
          <h2 className={classes.Title}><LocalizedMessage id="accessForm.title" /></h2>
          <p className={classes.SubTitle}><LocalizedMessage id="accessForm.subTitle" /></p>
        </header>
        <div
          className={cx(
            classes.MessageBox,
            classes.MessageBoxSuccess,
            {
              [classes.MessageBoxVisible]: isSubmitted,
            },
          )}
        >
          <span className={classes.Message}>
            <LocalizedMessage id="accessForm.submit.success" />
          </span>
        </div>
        <form
          className={classes.Form}
          ref={this.setFormRef}
          method="post"
          onSubmit={this.handleFormSubmit}
          autoComplete="off"
        >
          {isLoading && <Loader withBackground={false} />}
          <div className={cx(classes.InputWrapper, classes.InputWrapperPhone)}>
            <Input
              theme="round"
              type="tel"
              name="phone"
              autoComplete="off"
              maxLength={255}
              data-test="phone-input"
              defaultCountry="ru"
              onPhoneChange={this.handlePhoneChange}
              hasError={errors.phone !== undefined && errors.phone.length > 0}
              value={phone}
              ymRecordKeys
            />
          </div>
          <div className={classes.InputWrapper}>
            <LocalizedMessage id="accessForm.email.placeholder">
              {(placeholder) => (
                <Input
                  theme="round"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                  placeholder={placeholder}
                  autoComplete="off"
                  maxLength={255}
                  data-test="email-input"
                  hasError={errors.email !== undefined && errors.email.length > 0}
                  ymRecordKeys
                />
              )}
            </LocalizedMessage>
          </div>
          <div className={classes.InputWrapper}>
            <LocalizedMessage id="accessForm.company.placeholder">
              {(placeholder) => (
                <Input
                  theme="round"
                  type="text"
                  name="company"
                  value={company}
                  onChange={this.handleInputChange}
                  placeholder={placeholder}
                  autoComplete="off"
                  maxLength={255}
                  data-test="company-input"
                  hasError={errors.company !== undefined && errors.company.length > 0}
                  ymRecordKeys
                />
              )}
            </LocalizedMessage>
          </div>
          <div className={classes.ButtonWrapper}>
            <Button
              type="submit"
              theme="success"
              size="big"
              data-test="submit-button"
            >
              <LocalizedMessage id="accessForm.submitBtn" />
            </Button>
          </div>
        </form>
        <ul className={cx(
          classes.MessageBox,
          classes.MessageBoxError,
          {
            [classes.MessageBoxVisible]: errorValues.length > 0,
          },
        )}
        >
          {Object.values(Errors).map((id) => (errorValues.includes(id) && (
            <li key={id} className={cx(classes.Message)}>
              <LocalizedMessage id={id} />
            </li>
          )))}
        </ul>
        <p className={classes.Agreement}>
          <LocalizedMessage id={['accessForm.offerLink.text', 'accessForm.policyLink.text']}>
            {(offerLinkText, policyLinkText) => (
              <LocalizedHtml
                id="accessForm.agreement"
                values={{
                  offerLink: `<a href="/docs/offer">${offerLinkText}</a>`,
                  policyLink: `<a href="/docs/policy">${policyLinkText}</a>`,
                }}
              />
            )}
          </LocalizedMessage>
        </p>
      </div>
    );
  }
}

export default AccessForm;
