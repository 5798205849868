import config from 'config';

export const sendMail = async ({ phone, email, company }) => {
  const response = await fetch(`${config.api.host}/client/access-request/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Realm: 'ADV' },
    body: JSON.stringify({ phone, email, organisation: company }),
  });
  const data = await response.json();
  if (!response.ok) throw data.message;

  return data;
};
