import React, { Component } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import classes from './Scrollbar.module.scss';

class Scrollbar extends Component {
  scrollbars = null;
  content = null;

  componentDidMount () {
    this.calculateHeight();
  }

  UNSAFE_componentWillReceiveProps () {
    this.calculateHeight();
  }

  setScrollbarsRef = (ref) => {
    this.scrollbars = ref;
  };

  setContentRef = (ref) => {
    this.content = ref;
  };

  getContainer () {
    if (!this.scrollbars) {
      return null;
    }

    return this.scrollbars.container;
  }

  getView () {
    if (!this.scrollbars) {
      return null;
    }

    return this.scrollbars.view;
  }

  getScrollLeft () {
    if (!this.scrollbars) {
      return 0;
    }

    return this.scrollbars.getScrollLeft();
  }

  getScrollTop () {
    if (!this.scrollbars) {
      return 0;
    }

    return this.scrollbars.getScrollTop();
  }

  scrollLeft (left) {
    if (!this.scrollbars) {
      return;
    }

    this.scrollbars.scrollLeft(left);
  }

  scrollTop (top) {
    if (!this.scrollbars) {
      return;
    }

    this.scrollbars.scrollTop(top);
  }

  calculateHeight () {
    setTimeout(() => {
      const { autoWidth, autoHeightMax } = this.props;
      if (this.scrollbars && this.scrollbars.container && this.content) {
        if (autoWidth) {
          this.scrollbars.container.style.width = `${this.content.offsetWidth}px`;
        }
        if (autoHeightMax) {
          this.scrollbars.container.style.height = `${Math.min(this.content.offsetHeight, autoHeightMax)}px`;
        }
      }
    });
  }

  renderView = (props) => {
    const { viewProps, viewClassName } = this.props;
    const { style, className, ...otherProps } = props;

    const viewStyle = {
      ...style,
    };

    return (
      <div
        {...otherProps}
        className={cx(
          className,
          viewClassName,
        )}
        style={viewStyle}
        {...viewProps}
      />
    );
  };

  render () {
    const {
      children,
      className,
      autoWidth,
      hideHorizontalScrollbar,
      hideVerticalScrollbar,
      onScroll,
      onScrollStart,
    } = this.props;

    return (
      <Scrollbars
        ref={this.setScrollbarsRef}
        hideTracksWhenNotNeeded
        renderTrackHorizontal={hideHorizontalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderTrackVertical={hideVerticalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderView={this.renderView}
        onScroll={onScroll}
        onScrollStart={onScrollStart}
      >
        <div
          ref={this.setContentRef}
          className={cx(
            { [classes.ContentAutoWidth]: autoWidth },
            className,
          )}
        >
          {children}
        </div>
      </Scrollbars>
    );
  }
}

export default Scrollbar;
