import React from 'react';
import { Footer, LocalizedMessage, NavMenu } from 'components';

import classes from './AnalyticsPage.module.scss';

const reportUrl = 'https://datalens.yandex/xberygbg0lzil';

const AnalyticsPage = () => (
  <div className={classes.Layout}>
    <header id="top">
      <NavMenu />
      <section className={classes.Promo}>
        <hgroup>
          <h1 className={classes.Title}><LocalizedMessage id="analytics-page.promo.title" /></h1>
          <h2 className={classes.Subtitle}><LocalizedMessage id="analytics-page.promo.subtitle" /></h2>
        </hgroup>
      </section>
    </header>

    <main>
      <section className={classes.BiReport}>
        <iframe
          className={classes.Iframe}
          frameBorder={0}
          allowFullScreen
          title="Similarweb Final"
          src={reportUrl}
        />
      </section>
    </main>

    <Footer />
  </div>
);

export default AnalyticsPage;
