import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import cx from 'classnames';

import { ReactComponent as SkLogo } from 'assets/sk-logo-1.svg';
import { LocalizedMessage } from 'components';

import classes from './NavMenu.module.scss';

const items = [
  'connections',
  'about-system',
  'examples',
  'rates-and-prices',
  'request-access',
  'company',
];

class NavMenu extends React.PureComponent {
  state = {
    isMobileMenuOpened: false,
  };

  handleButtonIconClick = () => {
    this.setState(prevState => (
      { isMobileMenuOpened: !prevState.isMobileMenuOpened }
    ));
  };

  handleNavListClick = () => {
    const { isMobileMenuOpened } = this.state;
    if (isMobileMenuOpened) {
      this.setState({ isMobileMenuOpened: false });
    }
  };

  render () {
    const { isMainPage = false } = this.props;
    const { isMobileMenuOpened } = this.state;

    return (
      <nav className={classes.NavMenu}>
        <div
          className={cx(
            classes.ButtonIcon, {
              [classes.ButtonIconClose]: isMobileMenuOpened,
            },
          )}
          onClick={this.handleButtonIconClick}
        >
          <span className={classes.IconLine} />
          <span className={classes.IconLine} />
          <span className={classes.IconLine} />
          <span className={classes.IconLine} />
        </div>
        <div
          className={cx(
            classes.Links,
            {
              [classes.LinksColumn]: isMobileMenuOpened,
            },
          )}
        >
          {isMainPage ? (
            <span className={classes.Logo}>
              <LocalizedMessage id="pages.header.name" />
            </span>
          ) : (
            <a className={classes.Logo} href="/">
              <LocalizedMessage id="pages.header.name" />
            </a>
          )}
          <ul className={classes.NavList}>
            {
              items.map((item, index) => (
                <li key={index} className={classes.NavItem}>
                  <HashLink
                    to={`/#${item}`}
                    className={classes.NavLink}
                    onClick={this.handleNavListClick}
                  >
                    <LocalizedMessage id={`pages.menu.${item}`} />
                  </HashLink>
                </li>
              ))
            }
            <li className={classes.NavItem}>
              <Link
                to="/market-analytics"
                className={classes.NavLink}
              >
                <LocalizedMessage id="pages.menu.market-analytics" />
              </Link>
            </li>
          </ul>
          <a className={classes.NavButton} href="https://clientspace.aizek.io/auth">
            <LocalizedMessage id="button.login" />
          </a>
          <a className={classes.SkLink} href="https://sk.ru/" target="_blank" rel="noopener noreferrer">
            <SkLogo className={classes.SkLogo} />
          </a>
        </div>
      </nav>
    );
  }
}

export default NavMenu;
