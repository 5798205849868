import React from 'react';
import { withRouter } from 'react-router';
import Routes from 'routes';
import { Helmet } from 'react-helmet';

import { NotificationWrapper, Scrollbar } from 'components';

import classes from './App.module.scss';

const App = () => (
  <div className={classes.Root}>
    <Helmet
      titleTemplate="%s - Aizek Client Space"
      defaultTitle="Aizek Client Space"
    />
    <Scrollbar
      viewProps={{ id: 'main_content' }}
      className={classes.Scrollbar}
    >
      <main className={classes.Main}>
        <Routes />
      </main>
    </Scrollbar>
    <NotificationWrapper />
  </div>
);

export default withRouter(App);
