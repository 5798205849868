import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as SkLogo } from 'assets/sk-logo-2.svg';
import { LocalizedMessage } from 'components';

import classes from './Footer.module.scss';

const currentYear = new Date().getFullYear();

const items = ['copyright', 'docs.policy', 'docs.offer', 'contacts',
  'contacts.email', 'contacts.tel', 'contacts.name', 'contacts.address'];

const Footer = () => (
  <LocalizedMessage id={items.map(item => `pages.footer.${item}`)}>
    {(copyright, policy, offer, contacts, email, tel, name, address) => (
        <footer className={classes.Footer} id="company">
          <div className={classes.FooterLeft}>
            <div>
              <h2 className={classes.Title}>
                {`© ${currentYear} ${copyright}`}
              </h2>
              <p><Link to="/docs/policy" className={classes.Link}>{policy}</Link></p>
              <p><Link to="/docs/offer" className={classes.Link}>{offer}</Link></p>
            </div>
            <a className={classes.SkLink} href="https://sk.ru/" target="_blank" rel="noopener noreferrer">
              <SkLogo className={classes.SkLogo} />
            </a>
          </div>
          <div className={classes.Contacts}>
            <h2 className={cx(
              classes.Title,
              classes.TitleLazur,
            )}
            >
              {contacts}
              :
            </h2>
            <p><a href={`mailto: ${email}`} className={classes.Link}>{email}</a></p>
            <p><a href={`tel: ${tel}`} className={classes.Link}>{tel}</a></p>
            <p>{name}</p>
            <p className={classes.Address}>{address}</p>
          </div>
        </footer>
    )}
  </LocalizedMessage>
);

export default Footer;
