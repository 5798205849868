import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MainPage from './MainPage';
import AnalyticsPage from './AnalyticsPage';
import PricingPage from './PricingPage';
import Docs from './Docs';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/docs/:docName"
      component={Docs}
    />
    <Route
      exact
      path="/market-analytics"
      component={AnalyticsPage}
    />
    <Route
      exact
      path="/price-list"
      component={PricingPage}
    />
    <Route
      exact
      path="/"
      component={MainPage}
    />
  </Switch>
);

export default Routes;
