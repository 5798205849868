import React from 'react';
import { HashLink } from 'react-router-hash-link';

import classes from './ArrowButton.module.scss';

const ArrowButton = ({ destinationId }) => (
  <HashLink
    to={destinationId}
    smooth
    className={classes.ArrowButton}
  >
    <i className={classes.ArrowButtonIcon} />
  </HashLink>
);

export default ArrowButton;
