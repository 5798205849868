import React from 'react';
import cx from 'classnames';
import ReactTelInput from 'react-telephone-input';

import { countriesWithFlags as countries } from 'helpers';

import classes from './Input.module.scss';
import flagsPath from './assets/flags.png';

const Input = (props) => {
  const {
    value,
    hasError,
    theme = '',
    type = 'text',
    disabled,
    ymRecordKeys,
    ...restProps
  } = props;

  if (type !== 'tel') {
    return (
      <input
        type={type}
        value={value || ''}
        className={cx(
          classes.Input,
          {
            [classes.InputRound]: theme === 'round',
            [classes.WithError]: hasError,
            [classes.InputDisabled]: disabled,
            'ym-record-keys': ymRecordKeys,
          },
        )}
        {...restProps}
      />
    );
  }

  const {
    onChange,
    onPhoneChange,
    defaultCountry,
    ...inputProps
  } = restProps;

  return (
    <ReactTelInput
      value={value}
      initialValue={value}
      defaultCountry={defaultCountry}
      flagsImagePath={flagsPath}
      className={cx(
        classes.Input,
        classes.InputPhone,
        {
          [classes.InputRound]: theme === 'round',
          [classes.WithError]: hasError,
          'ym-record-keys': ymRecordKeys,
        },
      )}
      inputProps={{ className: classes.InputPhoneField, ...inputProps }}
      buttonProps={{ className: classes.FlagButton }}
      onlyCountries={countries}
      onChange={onPhoneChange}
    />
  );
};

export default Input;
