const Config = {
  api: {
    host: process.env.REACT_APP_API_URL,
  },
  services: {
    yandexMetrika: {
      account: process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
  },
};

export default Config;
