import React, { PureComponent } from 'react';
import { HashLink } from 'react-router-hash-link';
import cx from 'classnames';

import { LocalizedMessage } from 'components';

import classes from './ScrollTopButton.module.scss';

class ScrollTopButton extends PureComponent {
  mainContainer = null;
  startSection = null;

  state = {
    isVisible: false,
  };

  componentDidMount () {
    const { startSectionId } = this.props;

    this.mainContainer = document.getElementById('main_content');
    this.startSection = document.getElementById(startSectionId.replace(/^#/, ''));

    if (this.mainContainer === null) {
      return;
    }
    this.mainContainer.addEventListener('scroll', this.handleMainContainerScroll);
  }

  componentWillUnmount () {
    if (this.mainContainer === null) {
      return;
    }
    this.mainContainer.removeEventListener('scroll', this.handleMainContainerScroll);
  }

  handleMainContainerScroll = () => {
    if (this.startSection === null) {
      return;
    }

    const documentHeight = document.documentElement.clientHeight;
    const { top, height } = this.startSection.getBoundingClientRect();
    const { isVisible } = this.state;

    const buttonInitialPosition = top + height + 50;
    const isButtonInitialPositionReached = documentHeight > buttonInitialPosition;

    if (isVisible !== isButtonInitialPositionReached) {
      this.setState({ isVisible: isButtonInitialPositionReached });
    }
  };

  render () {
    const { destinationId } = this.props;
    const { isVisible } = this.state;

    return (
      <div
        className={cx(
          classes.ScrollTopButton,
          {
            [classes.ScrollTopButtonVisible]: isVisible,
          },
        )}
      >
        <HashLink
          to={destinationId}
          smooth
          className={classes.ScrollTopButtonText}
        >
          <LocalizedMessage id="button.back-to-top" />
          <span className={classes.ScrollTopButtonArrow}>&nbsp;&#8593;</span>
        </HashLink>
      </div>
    );
  }
}

export default ScrollTopButton;
