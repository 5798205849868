import React from 'react';
import cx from 'classnames';
import classes from './Button.module.scss';

const Button = ({
  type = 'button',
  size = '',
  theme = '',
  icon = '',
  className = '',
  isActive = false,
  disabled = false,
  children = null,
  onMouseDown,
  onClick,
  'data-test': testId,
}) => (
  <button
    type={type}
    disabled={disabled}
    className={cx(
      classes.Button,
      {
        [classes.ButtonLight]: theme === 'light',
        [classes.ButtonDanger]: theme === 'danger',
        [classes.ButtonPrimary]: theme === 'primary',
        [classes.ButtonTransparent]: theme === 'transparent',
        [classes.ButtonTransparentDanger]: theme === 'transparent-danger',
        [classes.ButtonSuccess]: theme === 'success',
        [classes.ButtonSmall]: size === 'small',
        [classes.ButtonLarge]: size === 'large',
        [classes.ButtonBig]: size === 'big',
        [classes.ButtonActive]: isActive,
        [cx(classes.ButtonIcon, `icon-${icon}`)]: icon,
        [classes.ButtonWithoutChildren]: !children,
      },
      className,
    )}
    onMouseDown={!disabled ? onMouseDown : undefined}
    onClick={!disabled ? onClick : undefined}
    data-test={testId}
  >
    {children}
  </button>
);

export default Button;
