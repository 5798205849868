import React, { PureComponent } from 'react';
import cx from 'classnames';

import Countdown from './Countdown';
import classes from './Notification.module.scss';

const MAX_NOTIFIES = 4;
let ref = null;

const showNotification = (message, status = 'info') => {
  if (ref) {
    ref._showNotification(message, status);
  }
};

const Notification = React.memo((props) => {
  const { message, hideAlert, id, status } = props;

  return (
    <div className={cx(classes.Notification, { [classes.NotificationFailure]: status === 'failure' })}>
      <div>{message}</div>
      <Countdown hideAlert={() => hideAlert(id)} seconds={5} />
    </div>
  );
});

class NotificationWrapper extends PureComponent {
  state = {
    notifications: [],
  };

  componentDidMount () {
    ref = this;
  }

  _showNotification = (message, status) => {
    this.setState(({ notifications }) => ({
      notifications: [
        { message, status, id: new Date().toISOString() },
        ...(notifications.length + 1 < MAX_NOTIFIES ? notifications : notifications.slice(0, MAX_NOTIFIES - 1))],
    }));
  };

  handleHide = (id) => {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(n => n.id !== id),
    }));
  };

  render () {
    const { notifications } = this.state;

    return (
      <div className={classes.NotificationWrapper}>
        {notifications.map((n) => (
          <Notification
            key={n.id}
            message={n.message}
            status={n.status}
            id={n.id}
            hideAlert={this.handleHide}
          />
        ))}
      </div>
    );
  }
}

export { NotificationWrapper, showNotification };
