import React from 'react';

import { PDFViewer } from 'components';
import policyPdf from 'assets/policy.pdf';
import offerPdf from 'assets/offer.pdf';

const Docs = ({ match }) => {
  const pdfMap = {
    policy: policyPdf,
    offer: offerPdf,
  };
  const file = pdfMap[match.params.docName] ?? null;

  return file ? <PDFViewer file={file} /> : null;
};

export default Docs;
