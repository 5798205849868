import React, { useEffect, useMemo, useState } from 'react';
import pdfjsLib from 'pdfjs-dist/webpack';

import Page from './components/Page';

import './PDFViewer.scss';

const PDFViewer = ({ file }) => {
  const [pdf, setPdf] = useState(null);
  useEffect(() => {
    pdfjsLib.getDocument(file).promise.then((pdf) => {
      setPdf(pdf);
    });
  }, [file]);

  const numPages = useMemo(() => pdf?._pdfInfo.numPages ?? 0, [pdf]);

  if (pdf === null) {
    return null;
  }

  return (
    <div className="pdf-context">
      <div className="pdf-viewer">
        {
          [...Array(numPages).keys()].map((index) => (
            <Page
              pdf={pdf}
              index={index + 1}
              key={`pdf_page_${index}`}
              scale={1.7}
            />
          ))
        }
      </div>
    </div>
  );
};

export default PDFViewer;
