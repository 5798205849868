import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import cx from 'classnames';

import { AccessForm, ArrowButton, Footer, LocalizedMessage, NavMenu, ScrollTopButton } from 'components';
import Social from 'assets/social-network.jpg';
import Chart1 from 'assets/chart1.jpg';
import Chart2 from 'assets/chart2.jpg';
import Chart3 from 'assets/chart3.jpg';
import Chart4 from 'assets/chart4.jpg';

import classes from './MainPage.module.scss';

const MainPage = () => (
  <div className={classes.Layout}>
    <header id="top">
      <NavMenu isMainPage />
      <section className={classes.Promo}>
        <hgroup>
          <h1 className={classes.Title}><LocalizedMessage id="main-page.promo.title" /></h1>
          <h2 className={classes.Subtitle}><LocalizedMessage id="main-page.promo.subtitle" /></h2>
        </hgroup>
        <div className={classes.ButtonWrapper}>
          <Link
            to="#request-access"
            className={cx(
              classes.Button,
              classes.ButtonWithBorder,
            )}
          >
            <LocalizedMessage id="button.get-access" />
          </Link>
        </div>
        <ArrowButton destinationId="#connections" />
      </section>
    </header>

    <main>
      <section className={classes.Description} id="connections">
        <div className={classes.DescriptionImages}>
          <img
            className={classes.DescriptionSingleImage}
            src={Social}
            alt="social-network"
          />
        </div>
        <div className={classes.DescriptionText}>
          <h2 className={classes.DescriptionTitle}>
            <LocalizedMessage id="main-page.description.title" />
          </h2>
          <p>
            <LocalizedMessage id="main-page.description.subtitle" />
          </p>
          <div className={classes.ButtonWrapper}>
            <Link
              to="#request-access"
              className={classes.Button}
            >
              <LocalizedMessage id="button.get-access" />
            </Link>
          </div>
        </div>
      </section>
      <section className={cx(classes.Description, classes.DescriptionWide)} id="examples">
        <div className={classes.DescriptionImages}>
          <img
            className={classes.DescriptionImage}
            src={Chart1}
            alt="chart1"
          />
          <img
            className={classes.DescriptionImage}
            src={Chart2}
            alt="chart2"
          />
          <img
            className={classes.DescriptionImage}
            src={Chart3}
            alt="chart3"
          />
          <img
            className={classes.DescriptionImage}
            src={Chart4}
            alt="chart4"
          />
        </div>
        <div className={classes.DescriptionText}>
          <h3 className={classes.DescriptionSubtitle}>
            <LocalizedMessage id="main-page.description.text" />
          </h3>
        </div>
      </section>

      <section className={classes.Features} id="about-system">
        <div className={classes.FeaturesList}>
          <div className={classes.FeaturesItem}>
            <h3 className={cx(
              classes.FeatureTitle,
              classes.FeatureTitleGraph,
            )}
            >
              <LocalizedMessage id="main-page.features.graph.title" />
            </h3>
            <p>
              <LocalizedMessage id="main-page.features.graph.subtitle" />
            </p>
          </div>
          <div className={classes.FeaturesItem}>
            <h3 className={cx(
              classes.FeatureTitle,
              classes.FeatureTitleCalc,
            )}
            >
              <LocalizedMessage id="main-page.features.calc.title" />
            </h3>
            <p>
              <LocalizedMessage id="main-page.features.calc.subtitle" />
            </p>
          </div>
          <div className={classes.FeaturesItem}>
            <h3 className={cx(
              classes.FeatureTitle,
              classes.FeatureTitleLock,
            )}
            >
              <LocalizedMessage id="main-page.features.lock.title" />
            </h3>
            <p>
              <LocalizedMessage id="main-page.features.lock.subtitle" />
            </p>
          </div>
        </div>
        <div className={classes.ButtonWrapper}>
          <Link
            to="#request-access"
            className={classes.Button}
          >
            <LocalizedMessage id="button.get-access-to-system" />
          </Link>
        </div>
      </section>

      <section className={classes.Rates} id="rates-and-prices">
        <h2 className={classes.RatesTitle}>
          <LocalizedMessage id="main-page.rates.title" />
        </h2>
        <p className={classes.RatesSubtitle}>
          <LocalizedMessage id="main-page.rates.subtitle" />
        </p>
        <div className={classes.ButtonWrapper}>
          <Link
            to="#request-access"
            className={cx(
              classes.Button,
              classes.ButtonDark,
            )}
          >
            <LocalizedMessage id="button.get-cost-calculation" />
          </Link>
        </div>
      </section>

      <section className={classes.Form} id="request-access">
        <div className={classes.FormWrapper}>
          <AccessForm />
        </div>
      </section>
    </main>

    <ScrollTopButton destinationId="#top" startSectionId="#connections" />
    <Footer />
  </div>
);

export default MainPage;
