import React, { PureComponent } from 'react';

import classes from './Countdown.module.scss';

class Countdown extends PureComponent {
  state = {
    seconds: this.props.seconds || 4,
  };
  timerId = setInterval(() => this.tick(), 1000);
  duration = this.props.seconds || 4;

  componentDidUpdate () {
    const { hideAlert } = this.props;
    const { seconds } = this.state;

    if (!seconds) {
      hideAlert();
      clearInterval(this.timerId);
    }
  }

  componentWillUnmount () {
    clearInterval(this.timerId);
  }

  tick () {
    this.setState((state) => ({
      seconds: state.seconds - 1,
    }));
  }

  render () {
    const { seconds } = this.state;

    return (
      <div className={classes.Countdown}>
        <div className={classes.CountdownValue}>{seconds}</div>
        <svg className={classes.CountdownSvg}>
          <circle
            r="18"
            cx="20"
            cy="20"
            style={{ animationDuration: `${this.duration}s` }}
          />
        </svg>
      </div>
    );
  }
}

export default Countdown;
