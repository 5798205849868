import ReactHtmlParser from 'react-html-parser';

import locales from 'locales';

export const localizeMessage = (
  { id },
  values = {},
  messages = locales.ru,
) => {
  if (typeof messages[id] === 'undefined') {
    console.warn(new Error(`Localized string '${id}' not found.`));

    return '';
  }

  let resultMessage = messages[id];

  Object.entries(values).forEach(([key, value]) => {
    const re = new RegExp(`{${key}}`, 'g');
    resultMessage = resultMessage.replace(re, value);
  });

  return resultMessage;
};

export const LocalizedMessage = ({ children, id, values = {}, messages = locales.ru }) => {
  if (typeof children === 'function') {
    return children.apply(
      children,
      Array.isArray(id)
        ? id.map(localId => localizeMessage({ id: localId }, values, messages))
        : [localizeMessage({ id }, values, messages)],
    );
  }

  if (Array.isArray(id)) {
    return id.map(localId => localizeMessage({ id: localId }, values, messages)).join(' ');
  }

  return localizeMessage({ id }, values, messages);
};

export const LocalizedHtml = ({ children, id, values = {}, messages }) => {
  if (typeof children === 'function') {
    return children.apply(
      children,
      Array.isArray(id)
        ? id.map(localId => ReactHtmlParser(localizeMessage({ id: localId }, values, messages)))
        : [ReactHtmlParser(localizeMessage({ id }, values, messages))],
    );
  }

  if (Array.isArray(id)) {
    return id.map(localId => ReactHtmlParser(localizeMessage({ id: localId }, values, messages))).join(' ');
  }

  return ReactHtmlParser(localizeMessage({ id }, values, messages));
};
